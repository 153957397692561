$breakpoints: (
	small: 0,
	medium: 769px,
	large: 850px,
	xlarge: 1800px,
	xxlarge: 2000px
);
$breakpoint-classes: (small medium large xlarge);
@import url('https://fonts.googleapis.com/css?family=Montserrat|PT+Sans');

$global-width:1400px;

$paragraph-lineheight: 1.2rem;

$delta-blue: #20486A;
$delta-lightblue: #3498d8;
$delta-orange: #FF5500;
$delta-grey: #EDEDED;
$delta-green: #00b600;

//$button-background-hover: #aa4444;
$primary-color: $delta-blue; //blue
$secondary-color: $delta-orange;



$nav-font-color: #010000;
$anchor-font-family: 'Nunito', sans-serif;
$anchor-font-color: $delta-orange;
$default-text: $delta-grey;
$global-radius: 0;
$body-font-family: 'Nunito', sans-serif;
$body-font-color: black;
$header-font-family: 'Montserrat', sans-serif;
$header-color: black;
$anchor-font-color-hover: darken($delta-orange, 20);
$body-background: white;
$titlebar-color: $delta-grey;
$titlebar-icon-color: $delta-grey;

$button-color: $delta-grey;
$button-background: $delta-orange;
$button-background-hover: $delta-green;
$button-font-family: 'Montserrat', sans-serif;
$offcanvas-background: #777;
$offcanvas-exit-background: rgba(black, 0.5);

//$input-background: $delta-lightblue;
//$input-background-focus: darken($delta-lightblue, 10);
//$input-background-disabled: $delta-grey;
//$select-background: $delta-lightblue;
//$input-border: 1px solid $delta-grey;
//$input-border-focus: 1px solid $delta-grey;
$input-placeholder-color: #aaa;

$form-label-color: $delta-blue;
$form-label-font-size: 1.4rem;

$table-background: $delta-grey;
$breadcrumbs-item-color-current: white;

$offcanvas-background: white;
$offcanvas-sizes: (
  small: 100%,
  medium: 400px
);

$foundation-palette: (
  primary: $delta-blue,
  secondary: $delta-orange,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);

@import "foundation";
@include foundation-everything();
@import "motion-ui.scss";
@include motion-ui-transitions;
@include motion-ui-animations;

$fa-font-path: './fonts';
@import "font-awesome";

@import "../node_modules/select2/src/scss/core";
@import "jquery.typeahead.scss";

/*KJ additional*/
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,800;1,300&display=swap');