@import "components/settings";


h1.hintro {
  font-size:1.2rem;
	font-weight: 600;
	padding: 0.3rem;
 	position: relative;
	margin-top: 2rem;
    text-align: center;
    text-underline-offset: 10px;
    padding: 3px;
    text-decoration: underline 4px;
	text-decoration-color: $delta-orange;
  @include breakpoint(medium) {
    font-size: 1.3rem;
	margin-bottom: 2.5rem;
  }
  &:before {
    content: "";
    bottom: 0;
    width: 0px;
    border-top: 0px;
    position: absolute;
  }
}

h1 {
  font-size:1.2rem;
	font-weight: 600;
	padding: 0.3rem;
  position: relative;
  @include breakpoint(medium) {
    font-size: 1.3rem;
	margin-bottom: 2.5rem;
  }
  &:before {
    content: "";
    bottom: 0;
    left: 0;
    width: 210px;
    border-top: solid 4px $delta-orange;
    position: absolute;
  }
}

h2, h3 {
  position: relative;
  margin-bottom: 2.5rem;
  font-size:1.2rem;
	font-weight: 600;
  &:before {
    content: "";
    bottom: 0;
    left: 0;
    width: 210px;
    border-top: solid 4px $delta-orange;
    position: absolute;
  }
}

h5 {
	font-size: 1rem;
    font-weight: 600;
}

#title {
  img {
    max-height:90px;
  }
}

.top-bar-container {
  max-height:48px;
  background-color: #dedede;
  border-bottom: solid 2px $delta-lightblue;
  position: fixed;
  @include breakpoint(large) {
    position:relative;
  }

    
      z-index: 5;
    width: 100%;
  top: 0;

  .top-bar {
    padding:0;
    background-color: #dedede;
    ul.menu {
      background-color: #dedede;
    }
    .button {
      margin: 0;
      border-radius: 0;
      height: 46px;
      border-radius: 0;
      text-transform: uppercase;
      font-family: $header-font-family;
      padding: 1rem 2rem;
      margin: 0 2px;
      &:hover {
        background-color: $delta-orange;
      }
    }
  }

    p.flag-text {
      background-image: url('/assets/images/british_flag.jpg');
      background-repeat: no-repeat;
      padding-left: 5rem;
      line-height: 20px;
      background-position-y: center;
      background-position-x: 5px;
      font-family: $header-font-family;
      font-size: rem-calc(12px);
      margin:0.8rem 0;
    }
}

.title-bar {
  background-color: $delta-blue;
  border-bottom: solid 3px $delta-lightblue;
  color: white;
  z-index:10000;
  div {
    width: 25%;
  }

  a.telephone {
    background-image: url('/assets/images/telephone.png');
    background-repeat: no-repeat;
    background-size: 25px;
    height: 25px;
    display:block;
    background-position: center; 
  }
  a.email {
    background: url('/assets/images/email.png');
    background-repeat: no-repeat;
    background-size: 25px;
    height: 25px;
    display:block;
    background-position: center; 
  }  

  .summary-icon {
    &:before{
      @include fa-icon(); content: $fa-var-square; font-size: 1.5rem; color:$delta-orange;
    }
    font-size: rem-calc(12px);
    color: white;
    /*
    &:after {
      content: "Summary"; color: white; font-size: 0.6rem; display: block;
    }*/
  }
}

.header-container {
  background: url(/assets/images/header-bkgd.jpg) no-repeat white;
}

.header {
  text-transform: uppercase;
  text-align: center;
  color: white;
  padding: 1rem;
  height: 130px;
  font-family: $header-font-family;
  font-size: rem-calc(15px);

  margin-top:45px;

  @include breakpoint(large) {
    margin-top:0;
  }

  .delivery, .support {
    padding: 0.4rem;
  }


  a {
    font-size: 0.4rem;
    text-decoration: underline;
  }
}

.menu-container {
  background-color: #5a5c5db5;
  border-bottom: solid 3px #283A41;
  color: white;
  font-family: $header-font-family;
  .button {
    margin: 0;
    border-radius: 0;
    text-transform: uppercase;
    font-family: $header-font-family;
    padding: 1rem 2rem;
    margin: 0 2px;
	background-color:transparent;
    &:hover {
      background-color: $delta-orange;
    }
  }
  p {
    padding: 0.7rem 0 0;
    margin-bottom: 0;
    text-align: right;
  }
}



.main {

  @include breakpoint(large) {
    padding: 0.5rem 2rem;
    border: solid 1px #eee;

    h2 {
      padding-left:1rem!important;
      &:after {
        margin-left:1rem;
      }
    }
  }


  hr {
    border-bottom: solid 1px white;
  }
}

/*.online {
  @include breakpoint(large) {
    margin: 1rem;
    &.button {
      margin: 0;
    }
  }
}*/




.body-text {
  font-family: $body-font-family;
}

.items .item-button {
  display: block; width: 100%;
}


.footer-container {
  width:100%;
  background-color: #283A41;
  border-top: solid 3px #CBCBCB;
  color: white;
  font-family: $header-font-family;

  &.mobile {
    position: fixed;
    height: 55px;
    bottom: 0;
  }
  
  .button {
    margin: 0;
    border-radius: 0;
    text-transform: uppercase;
    font-family: $header-font-family;
    padding: 1rem 2rem;
    margin: 0 2px;
  	background-color: transparent;
    &:hover {
      background-color: $delta-lightblue;
    }
  }
  p {
    padding: 0.7rem 0;
    margin-bottom: 0;
    text-align: center;
  }
}

footer {
  .menu a {
    color: white;
    text-align: center;
    font-size: rem-calc(12px);
    font-weight: normal;
    font-family: $body-font-family;
    padding: 0;
  }
 
  .deltarubber {
    background-image: url('/assets/images/delta-logo.png');
    background-repeat: no-repeat;
    background-size: 80px;
    height: 50px;
    display:block;
    background-position: center;   
  }

  img {
    padding: 7px;
    height: 34px;
  }

  @include breakpoint(large) {
    font-family: 'Montserrat',sans-serif;
  }

/*
  .new-quote {

      background-image: url('/assets/images/new-quote.png');
      background-repeat: no-repeat;
      background-position: top; 
      background-size: 30px;
      display:block;
      padding-top:35px;
  }
  .retrieve-quote {
    background-image: url('/assets/images/retrieve-quote.png');
    background-repeat: no-repeat;
    background-size: 100px;
    height: 50px;
    display:block;
    background-position: center;   
  }
  .information {
    background-image: url('/assets/images/info.png');
    background-repeat: no-repeat;
    background-size: 100px;
    height: 50px;
    display:block;
    background-position: center;   
  }  */
}

#main-wrapper {
  &.grid-container {
    padding-left : 0;
    padding-right :0 ;

    @include breakpoint(large)
    {
      padding-left :.9375rem;
      padding-right :.9375rem;
    }
  }

  .intro {
    padding: 0 0.5rem ;

    .button {
      text-transform: uppercase;
      background-color: $delta-orange;
      box-shadow: 2px 2px 2px grey;
      &:not(.shape-button) {
        font-family: 'Montserrat' ,sans-serif;
    	  padding: 0.85em 3em;
      }
      &:hover {
        background-color:$delta-green;
      }
    }

    @include breakpoint(large) {
      background-color: #f7f7f7;
      margin:0 0 1rem;
      padding: 0.5rem 2rem;
    }
  }
}

.main-content {
  margin: 55px 0;
  padding: 0;
  min-height: calc(100vh - 110px);

  @include breakpoint(large)
  {
    min-height: 0;
    padding: 15px 0;
    margin: 0 auto;
  }


}

.offCanvasRight {
  $offcanvas-full-screen-side: right;
  $offcanvas-full-screen-bg: white;
  $offcanvas-full-screen-closebutton-size: 5rem;
  
  .offcanvas-full-screen {
    @include off-canvas-base($background: $offcanvas-full-screen-bg, $transition: 0.3s ease-in-out);
    @include off-canvas-position($position: $offcanvas-full-screen-side, $sizes: (small: 100%));
  }
  
  .offcanvas-full-screen-inner {
    padding: 1rem;
    text-align: left;
  
    h2 {
      font-weight: bold;
  
    }
  }
  
  .offcanvas-full-screen-menu {
    @include menu-base;
    @include menu-direction(vertical);
  
    a {
      color: $white;
  
      &:hover {
        color: darken($white, 30%);
      }
    }
  }
  
  .offcanvas-full-screen-close {
    color: #dedede;
    font-size: 3rem;
    float: right;
    padding: 0 0.8rem 0.1rem;
    border-radius: 0;
    border: solid 2px #dedede;
    outline: none;
  
  
    &:hover {
      color: #dedede;
    }
  }
}


 // $offcanvas-full-screen-side: left;
  $offcanvas-full-screen-bg: white;
  $offcanvas-full-screen-closebutton-size: 5rem;
  
  .offcanvas-full-screen-left {
    $offcanvas-full-screen-side: left;
    @include off-canvas-base($background: $offcanvas-full-screen-bg, $transition: 0.3s ease-in-out);
    @include off-canvas-position($position: $offcanvas-full-screen-side, $sizes: (small: 100%));
  }
  
  .offcanvas-full-screen-right {
    $offcanvas-full-screen-side: right;
    @include off-canvas-base($background: $offcanvas-full-screen-bg, $transition: 0.3s ease-in-out);
    @include off-canvas-position($position: $offcanvas-full-screen-side, $sizes: (small: 100%));
  }

  .offcanvas-full-screen-inner {
    padding: 1rem;
    text-align: left;
  
    h2 {
      font-weight: bold;
  
    }
  }
  
  .offcanvas-full-screen-menu {
    @include menu-base;
    @include menu-direction(vertical);
  
    a {
      color: $white;
  
      &:hover {
        color: darken($white, 30%);
      }
    }
  }
  
  .offcanvas-full-screen-close {
    color: #dedede;
    font-size: 3rem;
    float: right;
    padding: 0 0.8rem 0.1rem;
    border-radius: 0;
    border: solid 2px #dedede;
    outline: none;
  
  
    &:hover {
      color: #dedede;
    }
  }

/* gasket builder */

#quote {
  margin-top:4rem;
  @include breakpoint(medium) {
    margin-top: 0.5rem;
  }
  .summary {
    .title {
      font-weight: bold;
    }
  }
}

.error {
  color: red;
  &:after {
    content: ' this field is required';
    font-size: 0.8rem;
  }
}
.email-error {
  color: red;
  &:after {
    content: ' invalid email address';
    font-size: 0.8rem;
  }
}

.dimension-error {
  color: red;
  &:after {
    content: ' the flange is too narrow, please see guidelines';
    font-size: 0.8rem;
  }

  &[for="HD"]:after {
    content: ' must be within flange, please see guidelines';
  }

  &[for="PCD"]:after {
    content: ' must be within flange, please see guidelines';
  }

  &[for="material-thickness"]:after {
    content: ' must be less than flange, please see guidelines';
  }
}

.negative-error {
  color: red;
  &:after {
    content: ' cannot be negative';
    font-size: 0.8rem;
  }
}

.max-error {
  color: red;
  &:after {
    content: ' too large (> 1000mm) ';
    font-size: 0.8rem;
  }
}


.max-warning {
  color: red;
  &:after {
    content: ' too large for instant pricing (> 1000mm) quote will be submitted for evaluation';
    font-size: 0.8rem;
  }
}

.typeerror {
  color: red;
  &:after {
    content: ' !file type is not acceptable and will not be submitted';
    font-size: 0.8rem;
  }
}

.sizeerror {
  color: red;
  &:after {
    content: ' !file is too large';
    font-size: 0.8rem;
  }
}

.storageerror {
  color: red;
  &:after {
    content: ' !local storage is full, file cannot be saved';
    font-size: 0.8rem;
  }
}

.success {
  color: green;
  &:after {
    content: ' OK';
    font-size: 0.8rem;
  }
}


#shapes {

  .shape-button {
    cursor: pointer;
    font-family: 'PT Sans', sans-serif;
  }

  visibility: hidden;
  
  @include breakpoint(large) {
    visibility: visible;
  }

  .cell {
    background-color: white;
    text-align: center;
  }

  img {
    padding: 2rem 0;
  }

  .button {
    width: 100%;
    margin-bottom: 0;
    min-height: 3.5rem;
  }
}

#dimensions {
  .diagram {
    background:white;
    text-align: center;
    margin-bottom: 1rem;
  }
}

#materials {
  .price {
    margin: 2rem 0;
    font-size: 1.4rem;
  }
}

/*textarea, input[type=text], input[type=number], input[type=email], select {
  color: white !important;
}*/

label {
  font-size: 1rem;
}

#content-area {
  h2 {
	font-family: 'Montserrat';
    font-size:1rem;
    background-color: $delta-lightblue;
    color: white;
    text-transform: uppercase!important;
    padding: 0.3rem;
    margin-bottom: 0.5rem; 
    text-align: center;
    &:after {
      content: none;
    }
	  &:before {
      content: none;
    }

    @include breakpoint(large) {
      position: relative;
      margin-bottom: 2.5rem;
      background-color: transparent;
      color: black;
      text-align: left;
    }
  }


  input[type=email], input[type=number], input[type=text], select, textarea {
    margin-bottom: 0;
  }
}

#large-bottom {
  font-sizE: 0.8rem;
  text-align: center;
  margin-top:1rem;
}

.form-error {
  &.is-visible {
    display: inline-block !important;
  }
  
  margin-bottom:0;
}

.is-invalid-input:not(:focus) {
  color: black !important;
}

.breadcrumbs li {
  color: $delta-lightblue;
}

.paid-Y {
  background-color: $delta-green;
  color: white;
}
.type-Q {
  color: $delta-blue;
}

.payment-OK {
  border: solid 2px $delta-green;

}

.main .button {
	width: 100%;
	padding: 0.85em 1em;
	text-align: centre;
	@include breakpoint(medium) {
		width: 90%;
	}
	&.pay, &.submit {
		background-color: $delta-green;
		&:hover {
			background-color: $delta-lightblue;
		}
	}
}


/*KJ Additional*/

.index-grid.grid-margin-x>.medium-4 {
	text-align: center;
}

.index-grid.grid-margin-x>.medium-4 p {
	text-transform: uppercase;
	font-weight: bold;
}

.index-grid.grid-margin-x>.medium-4 img {
  max-width:200px!important;
  padding:1rem;
}

.index-box a.button {
    width: 100%!important;
    margin: 0;
    font-weight: bold;
}

.stepbkgd {
    background: linear-gradient(
180deg
, rgba(0,0,0,0) calc(50% - 1px), #0f486d calc(50%), rgba(0,0,0,0) calc(50% + 1px));
	padding-left: 2rem;
	margin-top: 2rem;
}

.buttonicon {
	margin-bottom: 2rem;
	cursor: pointer;
	max-width: 220px;
}

.buttonicon p {
    text-transform: uppercase;
    font-size: 0.85rem;
    margin-left: 10%;
    margin-right: 10%;
	line-height: 1.2rem;
    margin-top: 1rem;
	font-weight: 700;
    color: #0f486d;
}

.grid-x.grid-margin-x.medium-up-4 .cell.image {
    margin-bottom: 1rem;
}

.desintro p.title {
	font-weight: 700;
}

.desintro {
	margin-top: 1rem;
	margin-bottom: 3rem;
}

.grid-x.grid-margin-x.medium-up-4.shapes {
	margin-top: 1rem;
}

.buttonicon:hover p {
    color: #fe590e;
}

.promo3 {
	background: #283A41;
	height: 51px;
	border-bottom: 5px solid #747474;
	text-align: center!important;
	color: white;
	font-size: 14px;
}

.promoborderright p {
    line-height: 1.25rem;
    margin-top: 0.7rem;
	border-right: 1px solid lightgrey;
}

.promonoborder p {
    line-height: 1.25rem;
    margin-top: 0.7rem;
}

.medium-12.cell.shapeintro {
    margin-top: 1rem;
	margin-bottom: 3rem;
    text-align: center;
    font-size: 1.4rem;
	color: #0f486d;
}

.medium-12.cell.shapeintro p {
    font-family: 'Montserrat';
}

ul.inline-list {
  margin-bottom: 0;
  li { display: inline-block; }
}

#gasket-designer {
  input:focus, select:focus, textarea:focus {
    background-color: #ffffff;
	  border: 2px solid #fe5604;
  }
}

.quotetitle {
	color: #0f486d;
    border-bottom: 2px solid #0f486d;
    width: fit-content;
	font-weight: 600;
	text-transform: uppercase;
	margin-right: 3rem;
	font-family: 'Montserrat';
}

input[type="checkbox"] {
    margin-bottom: 0px;
}

.footer p {
	font-size: 12px;
	color: white!important;
	text-align: center;
	font-weight: 700;
}

.footer {
	font-size: 12px;
	color: white!important;
	text-align: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.footer a {
	font-size: 14px;
	color: white!important;
}

.footer a:hover {
	font-size: 14px;
	color: white!important;
	text-decoration: underline;
}

.centeredindex {
	max-width: 360px;
    margin: 0 auto;
	text-align: center;
	border: 1px solid black;
	box-shadow: 2px 2px 3px grey;
	margin-bottom: 1rem!important;
}

.centeredindex p {
	text-align: center;
	font-size: 0.9rem;
    font-weight: 700;
    margin-top: 1rem;
	padding-left: 1rem;
    padding-right: 1rem;
}

.centeredindex img.sq {
	max-height: 130px;
	padding: 1rem;
}

a.button.indexlink {
	margin-bottom: 0;
	width:100%;
}

p#title {
	text-align: left;
}



.medium-auto {
/*    border-left: 2px solid lightgrey;
    box-shadow: 0px 0px 7px 2px lightgrey;
	padding: 0px;*/

    p {
        margin-bottom: 0px;
        font-size:13px;
    }

    h5 {
        font-weight: 600;
        margin-top: 1rem;
        text-transform: uppercase;
        font-family: 'Montserrat';
        font-size: 0.9rem;
    }

    h4 {
        margin-bottom: 0.7rem;
        margin-top: 1rem;
        text-transform: uppercase;
        font-family: 'Montserrat';
        font-size: 1.2rem;
        font-weight: 600;
    }
}

button.button.summary {
    position: relative;
    top: -3.3rem;
	margin-right: 3rem;

	&:after {
        border-top: 0px solid transparent;
        border-right: 25px solid $delta-orange;
        border-bottom: 15px solid transparent;
        content: "";
        position: absolute;
        right: 25%;
        top: 39px;
        width: 0;
        height: 0;
        transition: border-color .25s ease-out,color .25s ease-out;
    }
    &:hover:after, &:focus:after {
        border-top: 0px solid transparent;
        border-right: 25px solid $delta-green;
        border-bottom: 15px solid transparent;
        content: "";
        transition: border-color .25s ease-out,color .25s ease-out;
    }
}

.summaryinner {
  	width:100%;
  	padding: 2rem;
  	border: 0px;
}

.medium-4.large-4.cell.medium-order-2 .summaryinner {
	border: 2px solid lightgrey;
}


.summary p {
    font-size: 0.8rem;
    font-family: 'Montserrat';
}

.summary h4 {
    font-size: 1.2rem;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: bold;
}

.summaryinner p {
	font-size: 0.8rem;
    font-family: 'Montserrat';
}

.summaryinner h4 {
    font-size: 1.2rem;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: bold;
}

.dimension-image {
    padding-right: 2rem;
    padding-top: 1rem;
}

.button.mob {
    background: #0f486d;
    margin-bottom: 1px;
    &:hover {
        background: $delta-orange;
    }   
}

[type=number] {
	font-size: 0.9rem;
}

.select {
    font-size: 0.9rem;
}

.showhide {
	position: relative;
    top: -3.3rem;
}

p.notice {
	text-align: center;
	border: 3px solid #00b600;
	padding: 1rem;
	font-weight: bold;
	max-width: 800px;
	margin: 0 auto;
	margin-bottom: 1rem;
}

.centeredindex:hover .button {
	background-color: #00b600;
}

.centeredindex:hover {
	border:1px solid #00b600;
}

p.button.indexlink {
    margin-top: 0rem!important;
    margin-bottom: 0rem!important;
    width: 100%!important;
}

.test {
	color:black;
}
/*KJ1*/

.chosen {
  border: 3px solid  $delta-green;
}
.declined {
  opacity: 0.3;
}

.pager {
  list-style: none;
  li {
    display: inline-block;
    padding: 0.5rem;
    margin-left: 1rem;
    border: solid 1px #ccc;
    cursor: pointer;
    &.current {
      border: solid 1px #999;
      background: #ddd;
    } 
  }
}
.step {
  font-size: 1rem;
  position:relative;
  cursor: pointer;
  padding:0.8rem 1.15em;
  margin: 0 0.5rem;
  border-radius: 100%;
  background: #E0E0E0;
  &:after {
    border-radius: 100%;
    border: solid 3px #0f486d;
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 3rem;
    content: "";
  }
  &.active {
    background: white;
    &:after {
      border-color: #f50;
    }
  }
  &.disabled {
    background: #fff;
    color: #ddd;
    &:after {
      border-color: #ddd;
      //border-color: #ddd;
    }
    cursor: default;
  }
}

.buttonicon:focus {
	outline: none!important;
}
